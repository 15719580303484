import { Component, Inject, OnInit } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CtxButtonComponent } from '../button/button.component';
import { CtxForm } from '../../_forms/models/form.model';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CtxAlertComponent } from '../alert/alert.component';
import { DataCacheService } from '../../_services/data-cache.service';
import { SessionExpiryData } from 'utils';

@Component({
  selector: 'ctx-session-expiry',
  standalone: true,
  imports: [
    MatDialogModule,
    CtxButtonComponent,
    ReactiveFormsModule,
    MatInputModule,
    CtxAlertComponent,
  ],
  templateUrl: './session-expiry.component.html',
})
export class SessionExpiryComponent extends CtxForm implements OnInit {
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<SessionExpiryComponent>,
    public dataCacheService: DataCacheService,
    @Inject(MAT_DIALOG_DATA) public dialogData: SessionExpiryData
  ) {
    super();
  }
  profile = this.dataCacheService.getCachedValues('profile');
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      accountAlias: this.dialogData?.accountAlias,
      email: this.profile?.email,
      password: [this.dialogData?.password],
      twoFactorCode: [],
    });
  }
}
