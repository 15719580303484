<h2 mat-dialog-title>Session Expired</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(this.formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      @if (dialogData.loginState === 'email') {
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" />
      </mat-form-field>
      @if (profile?.twoFactorEnabled) {
      <mat-form-field>
        <mat-label>Two-Factor Code</mat-label>
        <input type="text" matInput formControlName="twoFactorCode" />
      </mat-form-field>
      } } @if (dialogData.loginState !== 'email') {
      <p>Your session has expired. Please log in again to continue</p>
      }
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [matDialogClose]="'close'"
      ctx-button--secondary
      [disabled]="this.formStates.disabled"
    >
      Close
    </button>
    @if (dialogData.loginState === 'email') {
    <button
      ctx-button--primary
      [loading]="this.formStates.submit"
      [disabled]="this.formStates.disabled"
      type="submit"
    >
      Log In
    </button>
    }
  </mat-dialog-actions>
</form>
