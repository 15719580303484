import * as localforage from 'localforage';

/** Camel cased names for all the items that are to be stored in the browser using localforage */
const LOCAL_FORAGE_ITEMS = [
  'accountId',
  'currentTheme',
  'accountAlias',
] as const;
export type LocalForageItem = (typeof LOCAL_FORAGE_ITEMS)[number];

/** Keys against which the data will be stored for each LocalForageItem  */
const LOCAL_FORAGE_KEYS: Record<LocalForageItem, string> = {
  accountAlias: 'CTX_ACCOUNT_ALIAS',
  accountId: 'CTX_ACCOUNT_ID',
  currentTheme: 'CTX_THEME',
} satisfies Record<LocalForageItem, string>;

/** Key for storing the accessToken in the localStorage */
const ACCESS_TOKEN_KEY = 'CTX_AT';

/** Key for storing the region in the localStorage */
const REGION_KEY = 'CTX_REGION';

/**
 * The PersistentStore is used to deal with Storage on the browser.
 * Primarly, we are using the localforage library which executes all storage actions asynchrounously
 * and dynamically decides whether to store the value in Session Storage or Local Storage.
 *
 * For the accessToken however, using the Promises provided by `localforage` is not feasible. In those cases,
 * we use the localStorage API directly, which is why we have separate functions for accessing the accessToken.
 */
export class PersistentStore {
  /**
   * @returns The value stored in the browser for the LocalForageItem
   */
  static get(item: LocalForageItem): Promise<string | null> {
    return localforage.getItem<string>(LOCAL_FORAGE_KEYS[item]);
  }

  /**
   * Stores the value for the LocalForageItem in browser storage
   */
  static set(item: LocalForageItem, value: string): Promise<string> {
    return localforage.setItem(LOCAL_FORAGE_KEYS[item], value);
  }

  /**
   * Removes the LocalForageItem from browser storage
   */
  static remove(item: LocalForageItem): Promise<void> {
    return localforage.removeItem(LOCAL_FORAGE_KEYS[item]);
  }

  /**
   * @returns The accessToken value stored in the localStorage
   */
  static getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Sets the accessToken in the localStorage
   */
  static setAccessToken(accessToken: string) {
    return localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  /**
   * Removes the access token from the localStorage
   */
  static removeAccessToken() {
    return localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Sets the region in the localStorage
   */
  static setRegion(region: string) {
    return localStorage.setItem(REGION_KEY, region);
  }
  /**
   * @returns  the region stored in the localStorage
   */
  static getRegion() {
    return localStorage.getItem(REGION_KEY);
  }
}
