import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CtxFormMode,
  CtxPlanResponse,
  ResourceCreateFormData,
  ResourceUpdateFormData,
  environment,
  projectEnv,
  reloadAfterSuccess,
  waitForSomeTime,
} from 'utils';
import { ResourceName } from 'utils';
import {
  CryptlexApiService,
  GlobalLoaderService,
  ScriptService,
  ThemeManagerService,
} from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceService } from 'ng-ui';
import { CardDeleteFormComponent } from './card-delete-form/card-delete-form.component';
import { StripeCardFormComponent } from './stripe-card-form/stripe-card-form.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

declare const Paddle: any;

@Injectable({
  providedIn: 'root',
})
export class PaymentCardService extends ResourceService {
  override resourceName: ResourceName = 'card';
  override resourceApiPath = '/v3/billing/payment-methods/cards';

  /**
   * Since we now support only on card per account.
   * The API response returns an array of length 1 when card is present
   * This helper retrieves that card if available or returns null.
   */
  get card() {
    const cards = this.dataCacheService.getCachedValues('card');
    if (cards && Array.isArray(cards) && cards[0]) {
      return cards[0];
    } else {
      return null;
    }
  }

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    private dialog: MatDialog,
    public router: Router,
    private scriptService: ScriptService,
    public themeManagerService: ThemeManagerService,
    private loaderService: GlobalLoaderService,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }
  get account() {
    return this.dataCacheService.getCachedValues('account');
  }

  get profile() {
    return this.dataCacheService.getCachedValues('profile');
  }

  /** Dialog that allows deleting card */
  async launchCardDeletionDialog() {
    const deletionDialog = this.dialog.open(CardDeleteFormComponent);
    await reloadAfterSuccess(deletionDialog.afterClosed(), this.router);
  }

  /** Dialog that allows adding paddle card */
  async launchAddPaddleCardDialog(
    setButtonState: (val: boolean) => void,
    paddlePlan?: CtxPlanResponse,
    planSubmit?: () => void,
    showLoadOnPlan?: () => void
  ) {
    await this.loadAndSetPaddle();
    this.addCard(paddlePlan?.paddleId, planSubmit, showLoadOnPlan);
    setButtonState(false);
  }

  /** Dialog that allows adding paddle billing card */
  async launchAddPaddleBillingCardDialog(
    setButtonState: (val: boolean) => void,
    paddlePlan?: CtxPlanResponse,
    planSubmit?: () => void,
    showLoadOnPlan?: () => void
  ) {
    await this.loadAndSetPaddleBilling();
    Paddle.Update({
      eventCallback: (event: any) => {
        setButtonState(false);

        /** In case card is added successfully */
        if (event.name === 'checkout.completed') {
          Paddle.Checkout.close();
          /** Card id removed from the cache */
          this.dataCacheService.invalidateCache(this.resourceName);

          if (showLoadOnPlan) {
            /** If card is added from the plan change model we show the loading sign on it */
            showLoadOnPlan();
          } else {
            /** If card is added from the card componenet, loader is shown on whole page  */
            this.loaderService.showLoader();
          }

          /** wait for changes to get reflected on backend */
          waitForSomeTime(20000).then(async () => {
            if (planSubmit) {
              /** If card is added from the plan change model,submit the opted plan after card is successfully added */
              planSubmit();
            } else {
              /** If card is added from the card componenet, hide loader from the screen and
               * make sure resolvers are run to fetch new card  details */
              this.loaderService.hideLoader();
              await this.router.navigate([this.router.url]);
            }
          });
        }
      },
    });
    this.addPaddleBillingCard(paddlePlan?.paddlePriceId);
  }
  /** Dialog that allows update paddle card */
  async launchUpdatePaddleCardDialog(setButtonState: (val: boolean) => void) {
    await this.loadAndSetPaddle();
    this.updateCard(this.card.updateUrl);
    setButtonState(false);
  }

  async launchUpdatePaddleBillingCardDialog(
    setButtonState: (val: boolean) => void
  ) {
    await this.loadAndSetPaddleBilling();
    /**Update the callback incase of update card  */
    Paddle.Update({
      eventCallback: (event: any) => {
        setButtonState(false);
        /** In case card is updated successfully */
        if (event.name === 'checkout.completed') {
          Paddle.Checkout.close();
          /** Card id removed from the cache */
          this.dataCacheService.invalidateCache(this.resourceName);
          this.router.navigate([this.router.url]);
        }
      },
    });
    this.updatePaddleBillingCard();
  }
  /** Dialog that allows Add stripe card */
  async launchAddStripeCardDialog(planSubmit?: () => void) {
    const creationDialog = this.dialog.open<any, ResourceCreateFormData>(
      StripeCardFormComponent,
      {
        data: {
          create: this.create.bind(this),
          mode: CtxFormMode.Create,
        },
      }
    );
    if (!planSubmit) {
      await reloadAfterSuccess(creationDialog.afterClosed(), this.router);
    } else if (
      planSubmit &&
      (await firstValueFrom(creationDialog.afterClosed()))
    ) {
      this.dataCacheService.invalidateCache(this.resourceName);
      planSubmit();
    }
  }

  /** Dialog that allows update stripe card */
  async launchUpdateStripeCardDialog() {
    const creationDialog = this.dialog.open<any, ResourceUpdateFormData>(
      StripeCardFormComponent,
      {
        data: {
          resource: this.dataCacheService.getCachedValues('account'),
          mode: CtxFormMode.Update,
          update: this.update.bind(this),
        },
      }
    );
    await reloadAfterSuccess(creationDialog.afterClosed(), this.router);
  }
  setUpPaddle() {
    if (
      environment.get('name') === 'development' ||
      environment.get('name') === 'local'
    ) {
      Paddle.Environment.set('sandbox');
      Paddle.Options({ debug: true });

      Paddle.Setup({
        vendor: projectEnv.get('paddleVendorId'),
      });
    } else if (environment.get('name') === 'production') {
      Paddle.Setup({
        vendor: projectEnv.get('paddleVendorId'),
      });
    }
  }

  setUpPaddleBilling() {
    if (
      environment.get('name') === 'development' ||
      environment.get('name') === 'local'
    ) {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Initialize({
      token: projectEnv.get('paddleToken'),
    });
  }
  async loadAndSetPaddle() {
    return this.scriptService.loadPaddle().then(() => {
      this.setUpPaddle();
    });
  }

  async loadAndSetPaddleBilling() {
    return this.scriptService.loadPaddleBilling().then(() => {
      this.setUpPaddleBilling();
    });
  }
  addCard(
    planId?: string,
    planSubmit?: () => void,
    showLoadOnPlan?: () => void
  ) {
    Paddle.Checkout.open({
      method: 'overlay',
      product: planId || this.account.plan.paddleId,
      allowQuantity: false,
      disableLogout: true,
      frameInitialHeight: 516,
      email: this.account.email,
      passthrough: `{"accountId": "${this.account.id}", "region": "${this.account.region}"}`,

      showAddTaxId: true,
      showAddDiscounts: false,
      displayModeTheme: this.themeManagerService.isDarkMode ? 'dark' : 'light',
      successCallback: () => {
        this.dataCacheService.invalidateCache(this.resourceName);
        if (showLoadOnPlan) {
          showLoadOnPlan();
        } else {
          this.loaderService.showLoader();
        }

        new Promise<void>((resolve) => {
          return setTimeout(() => {
            return resolve();
          }, 20000);
        }).then(async () => {
          if (planSubmit) {
            planSubmit();
          } else {
            this.loaderService.hideLoader();
            await this.router.navigate([this.router.url]);
          }
        });
      },
    });
  }

  addPaddleBillingCard(priceId?: string) {
    Paddle.Checkout.open({
      settings: {
        displayMode: 'overlay',
        theme: this.themeManagerService.isDarkMode ? 'dark' : 'light',
        locale: 'en',
        allowLogout: false,
        showAddDiscounts: true,
        frameInitialHeight: 516,
        showAddTaxId: true,
      },
      items: [
        {
          priceId: priceId || this.account.plan.paddlePriceId,
          quantity: 1,
        },
      ],
      customer: {
        email: this.profile.email,
      },

      customData: {
        accountId: this.account.id,
        region: this.account.region,
      },
    });
  }
  /**
   * Update the paddle card on the account
   * @param updateUrl url to update the card can be null if environment is production
   */
  updateCard(updateUrl: string | null) {
    Paddle.Checkout.open({
      override: updateUrl,
      method: 'overlay',
      frameInitialHeight: 500,

      displayModeTheme: this.themeManagerService.isDarkMode ? 'dark' : 'light',
      disableLogout: true,
      showAddTaxId: true,
      showAddDiscounts: false,
      successCallback: () => {
        this.dataCacheService.invalidateCache(this.resourceName);
        this.router.navigate([this.router.url]);
      },
    });
  }

  /**
   * Update the paddle billling card on the account
   * @param toggleButtonState fucntion to toggle card button state
   */
  async updatePaddleBillingCard() {
    const response = await this.apiService.get(
      `${this.resourceApiPath}/paddle-transaction`
    );
    Paddle.Checkout.open({
      settings: {
        displayMode: 'overlay',
        theme: this.themeManagerService.isDarkMode ? 'dark' : 'light',
        locale: 'en',
        allowLogout: false,
        showAddDiscounts: false,
        frameInitialHeight: 516,
        showAddTaxId: true,
      },
      transactionId: response.body.id,
    });
  }
}
