import {
  GOOGLE_CLIENT_ID,
  PADDLE_TOKEN_PROD,
  PADDLE_VENDOR_ID_PROD,
  POSTHOG_API_HOST,
  POSTHOG_API_KEY,
  RECAPTCHA_SITE_KEY,
  STRIPE_API_KEY_PROD,
} from '../types/constants';
import {
  Env,
  ProjectEnvironmentVariables,
  projectKeys,
} from '../types/environment.types';

export const projectEnv = new Env<ProjectEnvironmentVariables>(
  {
    projectName: 'admin-portal',
    stripeApiKey: STRIPE_API_KEY_PROD,
    googleClientId: GOOGLE_CLIENT_ID,
    releaseServerBaseUrl: '',
    recaptchaSiteKey: RECAPTCHA_SITE_KEY,
    posthogApiHost: POSTHOG_API_HOST,
    posthogApiKey: POSTHOG_API_KEY,
    paddleVendorId: PADDLE_VENDOR_ID_PROD,
    paddleToken: PADDLE_TOKEN_PROD,
  },
  projectKeys
);
